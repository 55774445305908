@mixin fs($size) {
  font-size: $size; //Fallback in px
  font-size: rem($size);
}
@mixin center($position) {
    position: absolute;

    @if $position == "vertical" {
        top: 50%;

        transform: translateY(-50%);
    }
    @else if $position == "horizontal" {
        left: 50%;

        transform: translate(-50%);
    }
    @else if $position == "both" {
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }
}
@mixin pseudo($display: block, $pos: absolute, $content: "") {
    position: $pos;

    display: $display;

    content: $content;
}
// Mixin to convert a font-size from px to rem

// @params {$fontsize} - Default to 16px, the value of the fontsize in px we want to convert to rem
// @params {$linesize} - Default to 22px, the value of the lineheight in px we want to convert to rem
$base-font-size: 16px;
@mixin font-size($font-size, $line-height: auto) {
    font-size: $font-size;
    font-size: ($font-size / $base-font-size) * 1rem;

    @if $line-height == auto {
        line-height: ceil($font-size / $base-line-height) * ($base-line-height / $font-size);
    }

    @else {
        @if (type-of($line-height) == number or $line-height == inherit or $line-height == normal) {
            line-height: $line-height;
        }
    }
}
@mixin css-triangle($color, $direction, $size: 6px, $position: absolute, $round: false) {
    @include pseudo($pos: $position);

    width: 0;
    height: 0;
    @if $round {
        border-radius: 3px;
    }
    @if $direction == down {
        margin-top: 0 - round( $size / 2.5 );

        border-top: $size solid $color;
        border-right: $size solid transparent;
        border-left: $size solid transparent;
    } @else if $direction == up {
        margin-bottom: 0 - round( $size / 2.5 );

        border-right: $size solid transparent;
        border-bottom: $size solid $color;
        border-left: $size solid transparent;
    } @else if $direction == right {
        margin-right: -$size;

        border-top: $size solid transparent;
        border-bottom: $size solid transparent;
        border-left: $size solid $color;
    } @else if  $direction == left {
        margin-left: -$size;

        border-top: $size solid transparent;
        border-right: $size solid $color;
        border-bottom: $size solid transparent;
    }
}
