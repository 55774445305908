body {
    font-family: $font-main;
    &.has-menu-open {
        .navbar {
            background-color: $secondary;
        }
    }
}
main {
    min-height: calc(100vh - 215px);
    > section {
        height: 100%;
    }
}
.homepage {
    &::before {
        position: fixed;
        z-index: -1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        content: "";

        background: linear-gradient(
            to bottom,
            rgba($black, 0) 0,
            rgba($black, 1) 100%
            );
    }
}

.container {
    // NOTE: Do not edit .container directly, but only its children.
    .container__intro {
        max-width: 850px;
        margin: 0 auto;
        margin-bottom: rem(30);
        padding-right: rem(15);
        padding-left: rem(15);

        text-align: center;
        h2 {
            font-size: rem(32);
            font-weight: 100;

            @include media-breakpoint-up(md) {
                font-size: rem(36);
            }
        }
        p {
            font-size: rem(18);
            font-weight: 100;
            line-height: 1.3em;

            letter-spacing: rem(0.5);
        }
        &.container__intro--properties {
            max-width: none;

            @include media-breakpoint-up(lg) {
                display: flex;

                align-items: center;
                justify-content: space-between;
                .container__heading {
                    width:100%;
                    text-align: left;
                }
                .btn[data-toggle=filter-offcanvas] {
                    min-width: 175px;
                    margin-right: 50px;
                }
            }
        }
    }
    .container__heading {
        font-size: rem(32);
        font-weight: bold;
        line-height: 1.2em;

        @include media-breakpoint-up(md) {
            font-size: rem(42);
        }
        @include media-breakpoint-up(xl) {
            font-size: rem(52);
        }
    }
    .container__pagination {
        display: flex;

        width: 100%;
        margin-bottom: rem(10);
        padding-left: 0;

        list-style: none;

        @include media-breakpoint-up(lg) {
            width: auto;
        }

        align-items: center;
        justify-content: flex-start;
        li {
            display: inline-block;
            a {
                display: flex;

                width: 30px;
                height: 30px;

                color: $primary;
                border-top: 1px solid rgba($black, 0.1);
                border-right: 1px solid rgba($black, 0.1);
                border-bottom: 1px solid rgba($black, 0.1);
                background-color: $white;

                align-items: center;
                justify-content: center;
                &:hover {
                    text-decoration: none;
                }
            }
            &:first-child {
                a {
                    border-left: 1px solid rgba($black, 0.1);
                }
            }
            &.active {
                a {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
}
.page-title {
    width: 100%;
    margin-bottom: rem(15);
    padding: rem(15);

    border-top: 1px solid $white;
    // background-color: $tertiary;
    background-color: $primary;

    @include media-breakpoint-up(md) {
        margin-bottom: rem(30);
        padding: rem(30) rem(0);
    }
    .page-title__heading {
        font-size: rem(36);
        font-weight: 100;

        color: $white;

        @include media-breakpoint-up(md) {
            font-size: rem(42);
        }
        @include media-breakpoint-up(lg) {
            font-size: rem(48);
        }
    }

    .page-title__container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: rem(15);
        padding-left: rem(15);

        @include media-breakpoint-up(sm) {
            max-width: 540px;
        }
        @include media-breakpoint-up(md) {
            max-width: 720px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 960px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 1140px;
        }
        @media all and (min-width: 1600px) {
            max-width: 1140px;
        }
    }
}
// NOTE: design went rogue
// .container {
//   @include media-breakpoint-up(sm) {
//     max-width: 540px;
//   }
//   @include media-breakpoint-up(md) {
//     max-width: 720px;
//   }
//   @include media-breakpoint-up(lg) {
//     max-width: 960px;
//   }
//   @include media-breakpoint-up(xl) {
//     max-width: 1240px;
//     padding-right: rem(25);
//     padding-left: rem(25);
//   }
//   @media all and (min-width: 1320px) {
//     padding-right: rem(0);
//     padding-left: rem(0);
//   }
//   @media all and (min-width: 1600px) {
//     max-width: 1420px;
//   }
// }
.content {
    padding: rem(15);

    @include media-breakpoint-up(lg) {
        padding: rem(15) rem(30);
    }
}
.contact-us {
    .page-title {
        display: none;
        margin-bottom: 0px;
    }
    .grey-wrapper {
        padding: 60px 0;
        background: #f3f1f2;
        border: solid 1px #f3f3f3;
        margin-bottom: 40px;
    }
    address {
        margin: 5px;
        padding: 0px;
        border-bottom: none;
    }
    .commercial-image {
        margin: 0px;
    }
    > .container {
        max-width: 100%;
        width: 100%;
        padding: 0px;
    }
    .embed-responsive {
        margin-bottom: 40px;
        &:before {
            @include media-breakpoint-up(md) {
                padding-bottom: 408px;
                padding-top: 0px;
            }
        }
    }
}
.footer-img-layout {
    background: white;
    padding: 20px 15px;
    margin-top: 10px;
    .footer__images img {
        max-height: 45px;
        margin: 10px 2em 10px 0px;
        opacity: 0.7;
    }
    .copyrights  {
        min-width: 300px;
        padding: 10px 0;
    }
}