.homepage__content {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;

  height: 100%;
  padding-top: rem(50);
  padding-bottom: rem(50);

  @include media-breakpoint-up(md) {
    margin-top: rem(50);
    margin-bottom: rem(50);
  }
  @include media-breakpoint-up(lg) {
    margin-top: rem(75);
    margin-bottom: rem(75);
  }
  .homepage__container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: rem(15);
    padding-left: rem(15);

    @include media-breakpoint-up(sm) {
      max-width: 540px;
      padding-right: rem(0);
      padding-left: rem(0);
    }
    @include media-breakpoint-up(md) {
      max-width: 720px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 960px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1240px;
      padding-right: rem(40);
      padding-left: rem(40);
    }
    @media all and (min-width: 1320px) {
      padding-right: rem(0);
      padding-left: rem(0);
    }
    @media all and (min-width: 1600px) {
      max-width: 1420px;
    }
  }
  h1 {
    max-width: 275px;
    margin-bottom: rem(15);

    text-transform: uppercase;

    color: $white;

    font-size: rem(46);
    font-weight: normal;
    line-height: 1.2em;

    @include media-breakpoint-up(md) {
      max-width: 420px;

      font-size: rem(62);
      font-weight: 100;
      line-height: 1.3em;
    }
    b,
    strong {
      font-weight: 900;
    }
  }
  p {
    max-width: 650px;
    margin-bottom: rem(25);

    color: $white;

    font-weight: 100;

    @include media-breakpoint-up(md) {
      margin-top: rem(25);
      margin-bottom: rem(40);
    }
    @include media-breakpoint-up(lg) {
      max-width: 700px;
      margin-top: rem(40);
      margin-bottom: rem(50);

      line-height: 1.8em;
    }
  }
  .landing-search {
    display: flex;
    justify-content: space-between;

    padding: rem(30);

    color: $white;
    background-color: $secondary;
    box-shadow: 0 0 20px 0 rgba($black, 0.2);

    @include border-radius(rem(10));
    @include media-breakpoint-up(md) {
      padding-top: rem(45);

      box-shadow: none;

      @include border-radius(rem(4));
    }
    @include media-breakpoint-up(lg) {
      padding-top: rem(30);
    }
    .chosen-container {
      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }
      @include media-breakpoint-down(md) {
        min-width: 100%;
      }
    }
    .landing-search__form {
      position: relative;

      width: 100%;

      @include media-breakpoint-up(md) {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: flex-start;
      }
      @include media-breakpoint-up(lg) {
        align-items: flex-end;
        flex-wrap: nowrap;
      }
      @include media-breakpoint-up(xxl) {
        align-items: center;
        justify-content: flex-start;
      }
      .form-group {
        // display: flex;
        // justify-content: space-between;
        width: 100%;

        @include media-breakpoint-up(lg) {
          width: auto;
          margin-bottom: 0;
        }

        &:last-child {
          @include media-breakpoint-down(sm) {
            margin-bottom: 0;
          }
        }
        label {
          font-size: rem(18);

          @include media-breakpoint-up(md) {
            text-align: center;
          }
          @include media-breakpoint-up(lg) {
            margin-bottom: rem(15);
          }
          @include media-breakpoint-up(xxl) {
            margin-bottom: rem(0);
          }
        }
        select {
          padding: rem(5) rem(10);

          @include border-radius(rem(4));
          // @include media-breakpoint-up(md) {
          //   @include border-radius(rem(0));
          // }
        }
        .btn {
          font-size: 16px;
          @include media-breakpoint-up(md) {
            margin-top: 0;
            min-height: 56px;
          }
        }
      }
      .search__validation {
        @include media-breakpoint-up(lg) {
          width: auto;
          min-width: 150px;
        }
      }
      .search__area {
        display: flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
          align-items: flex-start;
          flex-direction: column;

          width: auto;
          min-width: 320px;
          margin-right: rem(20);
          margin-left: rem(20);
          padding-right: 0;
        }
        @include media-breakpoint-up(xxl) {
          align-items: center;
          flex-direction: row;

          width: 100%;
          min-width: 0;
        }
        @include media-breakpoint-down(md) {
          flex-wrap: wrap;
        }
        label {
          margin-bottom: 0;

          @include media-breakpoint-up(lg) {
            margin-bottom: rem(15);
          }
          @include media-breakpoint-up(xxl) {
            margin-right: rem(20);
            margin-bottom: rem(0);
          }
          @include media-breakpoint-down(md) {
            width: 100%;
            text-align: left;
          }
        }
        select {
          width: 100%;
          margin-left: rem(15);
        }
      }
      .search__type {
        @include media-breakpoint-up(lg) {
          width: auto;
          min-width: 225px;
        }
        @include media-breakpoint-up(xxl) {
          display: flex;
          align-items: center;

          width: 100%;
          min-width: 0;
        }
        select {
          display: block;

          width: 100%;
        }
        label {
          @include media-breakpoint-up(xxl) {
            min-width: 150px;

            text-align: left;
          }
        }
        &.search__type--second {
          @include media-breakpoint-up(lg) {
            min-width: 150px;
          }
          @include media-breakpoint-up(xxl) {
            margin-left: rem(20);
          }
          label {
            @include media-breakpoint-up(lg) {
              visibility: hidden;
            }
            @include media-breakpoint-up(xl) {
              display: none;
            }
          }
        }
      }
    }
  }
}
