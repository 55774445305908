.btn {
  display: inline-block;

  margin-top: rem(10);
  padding: rem(15) rem(15);

  cursor: pointer;
  transition: 400ms;
  letter-spacing: 2px;
  text-transform: uppercase;

  border: 0;

  font-size: rem(14);

  @include border-radius(rem(0));

  &.btn--primary {
    letter-spacing: 3px;
    text-transform: uppercase;
  }
  &.btn--wide {
    display: inline-block;

    width: 100%;

    text-align: center;

    border-radius: 0;
    &:hover {
      transform: translateY(0);
    }
  }
  &.btn--red {
    color: $white;
    background-color: $primary;
  }
  &.btn--white {
    color: $primary;
    background-color: $white;
    box-shadow: 0 3px 10px 0 rgba($black, 0.1);
  }
  &.btn--outline {
    // border-bottom: 1px solid $primary;
    color: $primary;
    border-radius: 0;
    &:hover {
      box-shadow: none;
    }
  }
  &:hover {
    transform: translateY(-1px);
    text-decoration: none;

    box-shadow: 0 3px 10px 0 rgba($black, 0.2);
  }
  &[data-toggle="filter-offcanvas"] {
    margin-bottom: rem(15);
    padding: rem(5) rem(10);

    @include media-breakpoint-up(lg) {
      padding: rem(10) rem(15);
    }
  }
  &.btn--filter-close {
    position: absolute;
    top: rem(15);
    right: rem(15);

    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    margin: 0;

    border: 1px solid $secondary;
    background-color: white;

    @include border-radius(100%);
    @include media-breakpoint-up(md) {
      top: rem(30);
      right: rem(30);
    }
    span {
      display: block;
      height: 10px;
      width: 10px;

      transform: rotate(45deg);

      color: $primary;
      padding:0;
      display: flex;
      justify-content: center;
      align-items: center;

      font-size: rem(24);
      font-weight: bold;
      line-height: 2;
    }
  }
}
