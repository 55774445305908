.carousel {
    .carousel__item {
        width: 100%;
        height: 100%;

        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
    }
    .carousel__container {
        position: absolute;
        bottom: rem(15);

        width: calc(100% - 30px);

        color: $white;
        // NOTE : Need to integrate media-breakpoint-up with xs=360
        // @media all and (min-width: 360px) {
        //   width: calc(100% - 60px);
        //   margin-right: rem(30);
        //   margin-bottom: rem(30);
        //   margin-left: rem(30);
        // }

        @include media-breakpoint-up(sm) {
            margin-bottom: rem(30);
        }
        @include media-breakpoint-up(md) {
            margin-bottom: rem(45);
        }
        .carousel__tag {
            h3 {
                @include border-radius(rem(4));

                font-size: rem(12);
                font-weight: 300;

                display: inline-block;

                padding: rem(3) rem(5);

                letter-spacing: 0.5px;
                text-transform: uppercase;

                color: white;
                background-color: $secondary;

                @include media-breakpoint-up(md) {
                    margin-bottom: rem(10);
                    padding: rem(5) rem(10);

                    letter-spacing: 3px;
                }
                @include media-breakpoint-up(lg) {
                    padding: rem(10) rem(15);
                }
                @include media-breakpoint-up(xl) {
                    font-size: rem(14);

                    padding: rem(10) rem(20);
                }
            }
        }
        .carousel__content {
            margin-bottom: rem(15);
            h2 {
                font-size: rem(32);
                font-weight: normal;

                margin-top: 0;
                margin-bottom: 0;

                @include media-breakpoint-up(md) {
                    font-size: rem(56);
                    font-weight: bold;

                    margin-bottom: rem(10);
                }
                @include media-breakpoint-up(lg) {
                    font-size: rem(60);

                    margin-bottom: rem(15);
                }
                @include media-breakpoint-up(xl) {
                    font-size: rem(68);
                }
            }
            h3 {
                font-size: rem(18);
                font-weight: normal;
                line-height: 1.2em;

                margin-top: 0;
                margin-bottom: 0;

                @include media-breakpoint-up(md) {
                    font-size: rem(24);

                    margin-bottom: rem(40);
                }
                @include media-breakpoint-up(lg) {
                    font-size: rem(28);
                }
                @include media-breakpoint-up(xl) {
                    font-size: rem(32);
                }
            }
        }
        .carousel__details {
            ul {
                @include border-radius(rem(5));

                display: flex;

                width: 100%;
                max-width: 450px;
                margin-bottom: 0;
                padding: rem(10) rem(5);

                list-style: none;

                background: $tertiary;
                box-shadow: 0 0 20px 0 rgba($tertiary, 0.2);

                @include media-breakpoint-up(sm) {
                    width: 100%;

                    border: 1px solid $white;
                    border-radius: 0;
                    background: transparent;
                    box-shadow: none;
                }
                @include media-breakpoint-up(md) {
                    max-width: 600px;
                    padding: rem(15) rem(10);
                }

                li {
                    font-size: rem(14);

                    position: relative;

                    display: flex;
                    flex-direction: column;

                    min-width: rem(25);

                    @include media-breakpoint-up(sm) {
                        flex-direction: row;
                    }
                    @include media-breakpoint-up(md) {
                        font-size: rem(18);
                    }

                    align-items: center;
                    flex-grow: 1;
                    justify-content: center;
                    &:not(:last-child) {
                        &::before {
                            position: absolute;
                            top: 0;
                            right: 0;

                            width: 1px;
                            height: 100%;

                            content: "";

                            @include media-breakpoint-up(sm) {
                                background-color: $white;
                            }
                        }
                    }
                }
                span {
                    display: none;

                    @include media-breakpoint-up(sm) {
                        display: inline-block;

                        padding-right: rem(5);
                        padding-left: rem(5);
                    }
                    em {
                        font-size: rem(9);

                        position: absolute;
                    }
                }
            }
        }
    }
    &.carousel--main {
        height: 300px;

        @include media-breakpoint-up(md) {
            height: 400px;
        }
        @include media-breakpoint-up(lg) {
            height: 500px;
        }
        .carousel__item {
            &::before {
                position: absolute;
                top: 0;
                left: 0;

                width: 100%;
                height: 100%;

                content: "";

                background: linear-gradient(
                to top,
                rgba($black, 1) 0%,
                rgba($black, 0.85) 25%,
                rgba($black, 0.33) 75%,
                rgba($black, 0) 100%
                );

                @include media-breakpoint-up(sm) {
                    background: linear-gradient(
                    to top,
                    rgba($black, 1) 0%,
                    rgba($black, 0.75) 25%,
                    rgba($black, 0.15) 65%,
                    rgba($black, 0) 100%
                    );
                }
            }
        }
        .flickity-button {
            background-color: transparent;
        }
        .flickity-button-icon {
            fill: $white;
        }
        .flickity-prev-next-button {
            display: none;

            @include media-breakpoint-up(sm) {
                display: block;
            }
            &[disabled] {
                display: none;
            }
        }
    }
    &.carousel--property {
        height: 300px;

        @include media-breakpoint-up(md) {
            height: 400px;
        }
        @include media-breakpoint-up(lg) {
            height: 500px;
        }
        .flickity-button {
            background-color: transparent;
        }
        .flickity-button-icon {
            fill: $white;
        }
    }
    // &.carousel--sales-agreed {
    //     height: 100%;
    //     // .carousel__item {
    //     //     width: 100%;
    //     //     height: 200px;
    //     // }
    //     .flickity-button {
    //         background-color: transparent;
    //     }
    //     .flickity-button-icon {
    //         fill: rgba($black, 0.2);
    //     }
    //     .flickity-prev-next-button.previous,
    //     .flickity-prev-next-button.next {
    //         top: auto;
    //         bottom: -15px;
    //     }
    //     .flickity-prev-next-button.previous {
    //         left: -20px;

    //         @include media-breakpoint-up(md) {
    //             left: -20px;
    //         }
    //     }
    //     .flickity-prev-next-button.next {
    //         right: -20px;

    //         @include media-breakpoint-up(md) {
    //             right: -20px;
    //         }
    //     }
    // }
    &.carousel--sales-agreed,
    &.carousel--testimonials {
        height: 100%;

        color: $white;
        .flickity-button {
            background-color: transparent;
        }
        .flickity-prev-next-button.previous,
        .flickity-prev-next-button.next {
            top: auto;
            bottom: -18px;
        }
        .flickity-prev-next-button.previous {
            left: -10px;

            @include media-breakpoint-up(md) {
                left: -20px;
            }
        }
        .flickity-prev-next-button.next {
            right: -10px;

            @include media-breakpoint-up(md) {
                right: -20px;
            }
        }
        .flickity-viewport {
            transition: height 0.2s;
        }
        .flickity-button-icon {
            fill: $white ;
        }
        .carousel__item {
            width: 100%;
            height: auto;
        }
        .testimonial__container {
            padding: 5px;

            background-color: transparent;
        }
    }
    &.carousel--sales-agreed {
        .flickity-button-icon {
            fill: $black ;
        }
        .flickity-prev-next-button.previous,
        .flickity-prev-next-button.next {
            top: auto;
            bottom: -10px;
        }
    }
    &.carousel--nav {
        padding-top: rem(20);
        .carousel__item {
            width: 100px;
            height: 100px;
            margin-right: rem(10);

            @include media-breakpoint-up(md) {
                width: 150px;
                height: 150px;
            }
            @include media-breakpoint-up(lg) {
                width: 200px;
                height: 200px;
            }
        }
        .flickity-button {
            background-color: transparent;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .flickity-button-icon {
            fill: $tertiary;
        }
    }
}
