.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $primary;
  }
  ul {
    list-style: none;
    li {
      &::before {
        display: inline-block;

        width: 1em;
        margin-left: -1em;

        content: "•";

        color: red;
      }
    }
  }
  ol {
    list-style: none;

    counter-reset: li;
    li {
      counter-increment: li;
      &::before {
        display: inline-block;

        width: 1em;
        margin-left: -1em;

        content: counter(li);

        color: red;
      }
    }
  }
}
