.nav-pills {
    position: relative;
    padding: rem(20);

    @include media-breakpoint-up(lg) {
        margin-right: rem(30);
        margin-left: rem(30);

        border-bottom: 2px solid rgba($black, 0.2);
    }
    @include media-breakpoint-down(sm) {
        overflow-x: scroll;

        width: 100%;

        flex-wrap: nowrap;
    }

    .nav-item {
        @include media-breakpoint-down(sm) {
            margin-right: rem(15);
        }
        .nav-link {
            padding-right: rem(20);
            padding-left: rem(20);

            color: $secondary;
            border-radius: rem(25);

            @include media-breakpoint-up(lg) {
                border-radius: 0;
            }
            &.active {
                position: relative;

                color: $white;
                background: $primary;

                @include media-breakpoint-up(lg) {
                    color: $primary;
                    background-color: transparent;
                }
                &::after {
                    @include media-breakpoint-up(lg) {
                        position: absolute;
                        bottom: -2px;
                        left: 0;

                        width: 100%;
                        height: 2px;

                        content: "";

                        background-color: $primary;
                    }
                }
            }
        }
    }
}
