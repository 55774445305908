// Component : Cookie

.cookie {
  position: fixed;
  bottom: 0;
  left: 0;

  width: 100%;

  background-color: $white;
  box-shadow: 0 -5px 20px 0 rgba($black, .15);
  .cookie__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    padding: 15px;
    @include media-breakpoint-up(sm) {
      flex-direction: row;
    }
    p {
      margin-bottom: 20px;

      @include media-breakpoint-up(sm) {
        margin-bottom: 0;
      }
    }
    .btn {
      margin-top: 0;
    }
  }
  .cookie__buttons {
    display: flex;
    .btn {
      font-size: 12px;
    }
  }
}
