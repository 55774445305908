// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: DO NOT USE THIS IN OTHER PROJECTS
// NOTE: Ignoring this message will result in penalties.

.gbTimeline {
  display: none;
  /*bgTimeline.jpg*/
  // background: $limestone url('/assets/jquery.timeline/images/bgTimeline.jpg') center center no-repeat;
  //box-shadow: inset 0 0 80px rgba(0, 0, 0, 0.7);

  padding-bottom: 30px;

  background-size: cover;

  font-size: 16px;
  line-height: 24px;
}
.timelineLoader {
  width: 100%;
  padding: 150px 0;

  text-align: center;
}
.image_roll_zoom {
  position: absolute;

  display: none;
  display: inline-block;

  width: 100%;
  height: 100%;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
a.image_rollover_bottom:hover .image_roll_zoom {
  z-index: 10;
  top: -5% !important;
}
.image_roll_glass {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;

  opacity: 0;
  /*glass*/
  background: url("/assets/jquery.timeline/images/glass.png") repeat;
}
a.image_rollover_bottom:hover .image_roll_glass {
  z-index: 9;

  opacity: 1;
}
/*TIMELINE TOP SLIDER
*********************************************************************/
/* fixed line holder */

.gbTimeline .timeline_line {
  /*margin-bottom:10px;*/
  width: 920px;
  margin-top: 10px;
}
/* full (including months that are not shown) line holder */

.gbTimeline .t_line_holder {
  height: 130px;
  /*line.png*/

  //     background: url('/assets/jquery.timeline/images/line.png') repeat-x 0px 100px;
  &::before {
    position: absolute;
    bottom: 15px;
    left: 0;

    width: 100%;
    height: 9px;

    content: "";

    background-color: $white;
    box-shadow: inset 0 0 5px rgba(black, 0.5);
  }
}
/* 2 months are stored in one view */

.gbTimeline .t_line_view {
  width: 920px;
  height: 20px;
}
/* holder for year number */

.gbTimeline h3.t_line_year {
  margin: 0;

  color: #fff;
}
/* holder for 1 month (constist of nodes and month caption) - we use borders to separate months thats why it has width 2px less then 50% */

.gbTimeline .t_line_m {
  /*border-left:1px solid #fff;
	border-right:1px solid #fff;*/
  width: 459px;
  height: 10px;
  margin-top: 35px;
}
/* month on the right side - has left set at 459 so border would overlap border from first element (to evade duplicated borders) */

.gbTimeline .t_line_m.right {
  left: 459px;

  width: 459px;
}
/* month caption */

.gbTimeline h4.t_line_month {
  display: none;

  margin: -30px 0 0;

  color: #fff;
}
/* used in responsive layout when only one month is shown (it is span containing year) */

.t_line_month_year {
  display: none;
}
/* node on the timeline */

.gbTimeline a.t_line_node {
  top: 27px;

  min-width: 36px;
  height: 10px;
  padding: 0 0 58px;

  text-decoration: none;

  color: $white;
  /*time line dot.png*/
  background: url("/assets/jquery.timeline/images/dot.png") no-repeat center
    bottom;

  font-size: 12px;
  &::after {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;

    width: 20px;
    height: 20px;
    margin: 0 auto;

    content: "";

    border-radius: 100%;
    background-color: $tertiary;

    @include media-breakpoint-down(sm) {
      right: 80px;
      bottom: 6px;
      left: auto;

      width: 15px;
      height: 15px;
    }
  }
}
.gbTimeline a.t_line_node:hover,
.gbTimeline a.t_line_node.active {
  color: $primary;
  &::after {
    bottom: -5px;

    width: 30px;
    height: 30px;

    background-color: $primary;

    @include media-breakpoint-down(sm) {
      right: 77px;
      bottom: 6px;

      width: 20px;
      height: 20px;
    }
  }
}
/* node description */

.gbTimeline .t_node_desc {
  top: 4px;
  left: 25px;

  display: none !important;

  padding: 2px 10px;

  color: #fff;
  background: rgba(205, 23, 25, 0.7);
  background-color: $primary;
}
/* descriptions on right side go from right to left */

.gbTimeline .t_line_m.right .t_node_desc {
  right: 0;
  left: auto;
}
/* line arrow left */

.gbTimeline #t_line_left {
  top: 99px;
  left: -30px;

  width: 14px;
  height: 19px;

  cursor: pointer;

  opacity: 0.5;
}
.gbTimeline #t_line_left:hover {
  opacity: 1;
}
.gbTimeline #t_line_left:before {
  content: "\f104";
  text-decoration: inherit;
  /*--adjust as necessary--*/

  color: $secondary;

  font-family: "fontello";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
}
/* line arrow right */

.gbTimeline #t_line_right {
  top: 99px;
  right: -30px;

  width: 14px;
  height: 19px;

  cursor: pointer;

  opacity: 0.5;
}
.gbTimeline #t_line_right:hover {
  opacity: 1;
}
.gbTimeline #t_line_right:before {
  content: "\f105";
  text-decoration: inherit;
  /*--adjust as necessary--*/

  color: $secondary;

  font-family: "fontello";
  font-size: 24px;
  font-weight: normal;
  font-style: normal;
}
/*section nodes*/

span.sectionNode {
  position: relative;
  top: -32px;

  display: block !important;

  letter-spacing: -0.05em;

  line-height: 14px;
}
span.sectionNode strong {
  display: block;

  padding-right: 5px;
  padding-bottom: 5px;
  padding-left: 5px;

  letter-spacing: 0;

  font-size: 18px;
  line-height: 18px;
}
/*TIMELINE ITEMS
*********************************************************************/
/* items container */

.gbTimeline .timeline_items {
  padding: 10px 0;
}
/* single item (margines set from javascript) */

.gbTimeline .item {
  overflow: hidden;
  overflow-y: auto;

  width: 320px;
  height: 320px;

  border-radius: 4px;
  background: #030e15;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
}
.gbTimeline .item.sectionCard {
  background: $tertiary;
}
.gbTimeline .item.medium {
  width: 350px;
}
.gbTimeline .item.small {
  width: 280px;
}
.gbTimeline .item.item_node_hover {
  -webkit-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
}
/* ----- content - non-plugin elements ----- */

.gbTimeline .item.video > a {
  display: block;
}

.gbTimeline .item.video > a:before {
  position: absolute;
  z-index: 9;
  top: 35%;
  left: 50%;

  width: 56px;
  margin-top: -28px;
  margin-left: -28px;

  content: "\f144";

  color: $primary;

  font-family: "fontello";
  font-size: 56px;
  line-height: 56px;
}

.gbTimeline .item img {
  position: absolute;
  z-index: 1;

  width: 100%;
  height: auto;
  margin-top: 0;
}
.gbTimeline .item .text {
  position: absolute;
  z-index: 10;
  bottom: 0;

  width: 100%;
  margin-top: 0;
  padding: 20px;

  color: #1c2b36;
  background: rgba(255, 255, 255, 0.8);
  background-color: #fff;
}
.gbTimeline .item .text.read_more {
  cursor: pointer;
}
.gbTimeline .item.sectionCard .timeline__year {
  font-size: 18px;
  font-weight: 600;
}
.gbTimeline .item.sectionCard .timeline__title {
  font-size: 28px;
  font-weight: 300;
}
.gbTimeline .item.sectionCard .text {
  position: absolute;
  z-index: 10;
  bottom: 0;

  margin-top: 0;

  color: #fff;
  background-color: transparent;
}
.gbTimeline .item.quote .text {
  position: relative;
  bottom: auto;

  padding: 15px;
  padding-left: 30px;

  text-align: left;

  color: #ccd4da;
  background: rgba(3, 14, 21, 0.8);
  background-color: #030e15;

  font-style: italic;
  &::before {
    position: absolute;
    top: 15px;
    left: 10px;

    width: 5px;
    height: calc(100% - 30px);

    content: "";

    background-color: $primary;
  }
}
.gbTimeline .item .text.read_more:hover {
  background: #fff;
  background-color: #fff;
}
.gbTimeline .item.quote .text:hover {
  background: #030e15;
  background-color: #030e15;
}
.gbTimeline .item h2 {
  margin: 0 0 5px;

  color: #030e15;

  font-size: 19px;
}
.gbTimeline .item h2 strong,
.gbTimeline .item h2 b {
  color: $primary;
  //font-weight: normal;
}
.gbTimeline .item.sectionCard h2 {
  margin: 0 0 5px;

  color: #fff;

  font-size: 40px;
  line-height: 42px;
}
.gbTimeline .item.sectionCard h2 strong,
.gbTimeline .item.sectionCard h2 b {
  display: block;

  color: #fff;

  font-size: 20px;
}
.gbTimeline .item.quote h2 {
  margin: 0 0 8px;

  text-align: left;
  letter-spacing: 0;
  text-transform: none;

  color: #fff;

  font-size: 16px;
  font-style: normal;
  line-height: 23px;
}
.gbTimeline .item.quote h2:before {
  position: relative;
  top: -5px;

  padding-right: 5px;

  content: "\f10d";
  text-decoration: inherit;
  /*--adjust as necessary--*/

  color: #4e677a;

  font-family: "fontello";
  font-size: 16px;
  font-weight: normal;
  font-style: normal;
}
.gbTimeline .item span.read_more {
  position: relative;

  display: inline-block;

  width: 16px;
  height: 16px;
  margin: 0 0 0 5px;

  text-indent: -5000px;
}
.gbTimeline .item.quote span.read_more {
  top: 4px;
}
.gbTimeline .item span.read_more:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;


  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  content: "\e802";
  text-decoration: inherit;
  text-indent: 0;
  /*--adjust as necessary--*/

  color: $primary;

  font-family: "fontello";
  font-size: 14px;
  font-weight: 100;
}
/* ----------------------------------------- */
/* item details (margines set from javascript) */

.gbTimeline .item_open {
  position: relative;
  z-index: 2;

  width: 420px;
  border-radius: 4px;

  color: #1c2b36;
  background: rgba(255, 255, 255, 0.8);
  background-color: #fff;
  -webkit-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);

  font-size: 13px;
  line-height: 19px;
}
/* item details content wrapper (used for animation - shuld have same width as .item_open) */

.gbTimeline .item_open_cwrapper {
  width: 420px;
}
.gbTimeline .medium .item_open_cwrapper {
  /*width: 310px;*/
}
.gbTimeline .small .item_open_cwrapper {
  /*width: 200px;*/
}
/* ----- content - non-plugin elements ----- */

.gbTimeline .timeline_open_content {
  padding: 20px;
}
.gbTimeline .item_open h2 {
  margin-top: 10px;
  padding-top: 0;

  color: #030e15;

  font-size: 19px;
}
.gbTimeline .item_open h2 strong,
.gbTimeline .item_open h2 b {
  color: $primary;
}
.gbTimeline .item_open p {
  padding-bottom: 15px;

  font-size: 16px;
}
.gbTimeline .item_open .t_close {
  position: absolute;
  top: 10px;
  right: 10px;

  display: inline-block;

  width: 16px;
  height: 16px;

  cursor: pointer;
  text-indent: -5000px;
}
.gbTimeline .item_open .t_close:before {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 16px;
  height: 16px;

  content: "\e802";
  transform: rotate(45deg);
  text-decoration: inherit;
  text-indent: 0;
  /*--adjust as necessary--*/

  color: $primary;

  font-family: "fontello";
  font-size: 18px;
  font-weight: 100;
}
/* ----------------------------------------- */
/* left/right controles */

.gbTimeline .t_controles {
  // NOTE: remove it through JS better
  display: none;

  margin: 10px auto;

  text-align: center;
}
.gbTimeline .t_left,
.gbTimeline .t_right {
  position: absolute;
  z-index: 10;
  top: 0;

  display: block;

  width: 75px;
  height: 100%;
  margin: 0;

  cursor: pointer;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;

  background: rgba(3, 14, 21, 0.4);
  background-color: #030e15;
  // opacity: 0.8;
}
.gbTimeline .t_right {
  right: 0;
}
.gbTimeline .t_left:hover,
.gbTimeline .t_right:hover {
  opacity: 1;
  background: rgba(3, 14, 21, 0.7);
  background-color: #030e15;
}
.gbTimeline .t_left:before,
.gbTimeline .t_right:before {
  position: absolute;
  top: 48%;

  text-decoration: inherit;
  /*--adjust as necessary--*/

  color: #fff;

  font-family: "fontello";
  font-size: 60px;
  font-weight: normal;
  font-style: normal;
}
.gbTimeline .t_left:before {
  left: 40%;

  content: "\f104";
}
.gbTimeline .t_right:before {
  right: 40%;

  content: "\f105";
}
/* -----------------------------------------------------------------------*/
/* ------------------------------ RESPONSIVE -----------------------------*/
/* -----------------------------------------------------------------------*/
/* --- 768px --- */

@media screen and (max-width: 959px) {
  .gbTimeline .timeline_line {
    width: 728px;
  }
  .gbTimeline .t_line_view {
    width: 728px;
  }
  .gbTimeline .t_line_m {
    width: 362px;
  }
  .gbTimeline .t_line_m.right {
    left: 363px;

    width: 363px;
  }
  .gbTimeline .t_left,
  .gbTimeline .t_right {
    top: 23%;

    width: 35px;
    height: 70%;
  }
  .gbTimeline .t_left:before,
  .gbTimeline .t_right:before {
    top: 48%;

    font-size: 32px;
  }
}
/* --- 610px --- */

@media screen and (max-width: 767px) {
  .gbTimeline .timeline_line {
    width: 570px;
  }
  .gbTimeline .t_line_view {
    width: 1140px;
  }
  .gbTimeline .t_line_m {
    width: 568px;
  }
  .gbTimeline .t_line_m.right {
    left: 570px;

    width: 568px;
  }
  .gbTimeline .t_line_year {
    opacity: 0;

    filter: alpha(opacity=0);
  }
  .gbTimeline .t_line_month_year {
    display: inline;
  }
  .gbTimeline .t_line_node span {
  }
  .gbTimeline .t_node_desc {
    font-size: 8px;
  }
  .timelineLight .t_line_m.right .t_node_desc {
    right: auto;
    left: 0;
  }
}
/* --- 300px --- */

@media screen and (max-width: 609px) {
  .gbTimeline .timeline_line {
    width: 500px;
  }
  .gbTimeline .t_line_view {
    width: 520px;
  }
  .gbTimeline .t_line_m {
    width: 258px;
  }
  .gbTimeline .t_line_m.right {
    left: 260px;

    width: 258px;
  }
  .gbTimeline .item_open {
    width: 290px;
    height: 390px;
  }
  .gbTimeline .item_open_cwrapper {
    width: 290px;
  }
}

@media screen and (max-width: 520px) {
  .gbTimeline .timeline_line {
    width: 260px;
  }
}
/* -----------------------------------------------------------------------*/
/* ------------------------------ CUSTOM ---------------------------------*/
/* -----------------------------------------------------------------------*/

.gbTimeline {
  position: relative;
}

.gbTimeline .t_left,
.gbTimeline .t_right {
  top: 58%;

  max-height: 420px;

  transform: translateY(-50%);
}
.gbTimeline .t_left:before,
.gbTimeline .t_right:before {
  top: 50%;

  margin-top: -12px;
}
@media (max-width: 959px) {
  .gbTimeline .t_left:before,
  .gbTimeline .t_right:before {
    top: 129px;
  }
  .gbTimeline .item {
    overflow: hidden;

    width: 380px;
    height: 380px;

    background: #030e15;
  }
  .gbTimeline .t_left,
  .gbTimeline .t_right {
    top: 40%;

    max-height: 50px;

    transform: none;
  }

  .gbTimeline .t_left:before,
  .gbTimeline .t_right:before {
    top: 25px;
  }

  .gbTimeline #t_line_right {
    right: -22px;
  }

  .gbTimeline #t_line_left {
    left: -16px;
  }
  .gbTimeline .item span.read_more:before {
    top: 2px;
  }
}

@media (max-width: 700px) {
  .gbTimeline .item,
  .gbTimeline .item.medium,
  .gbTimeline .item.small {
    width: 300px;
    height: 300px;
  }

  .gbTimeline a.t_line_node {
    top: 20px;

    width: 100px !important;
    padding-bottom: 30px;
    padding-left: 34px;

    transform: rotate(-90deg);
    text-align: left !important;

    background-position: left bottom !important;
  }

  span.sectionNode strong {
    padding-top: 15px;

    font-size: 16px;
    line-height: 16px;
  }
}

.gbTimeline {
  .timeline__year {
    display: block;

    color: $primary;

    font-weight: 400;
  }
  .timeline__title {
    display: block;

    margin-top: rem(10);

    line-height: 1.2;
  }
  .timeline__image {
    position: relative;

    width: 100%;
    padding: 15px;
    img {
      position: relative;

      width: 100%;
      max-width: 150px;
    }
  }
  .item {
    &.sectionCard {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;

      width: 250px;
      .text {
        position: relative;
      }
    }
    .text {
      font-size: 16px;
      line-height: 1.3;
    }
    &.quote {
      color: $tertiary;
      height: auto;
    }
  }
}
