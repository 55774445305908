// !: INIT Component – Testimonials

.testimonial {
    margin-bottom: 10px;

    @include media-breakpoint-up(md) {
        margin-bottom: 30px;
    }
}

.testimonial__container {
    position: relative;

    padding: 15px;

    border-radius: 4px;
    background-color:$primary;

    @include media-breakpoint-up(md) {
        padding: 30px;
    }

}

.testimonial__content {
    background-color: white;
    border-radius: 10px;
    padding:20px;
    position: relative;
    &::before {
        @include pseudo;
        left:5px;
        top: 5px;

        width: 50px;
        height: 50px;

        background-image: url("../images/icon-quote.svg");
        background-repeat: no-repeat;
        background-position: center center;
        background-size: contain;

        @include media-breakpoint-up(md) {
            width: 90px;
            height: 90px;
        }
    }
    &::after {
        @include pseudo;
        @include css-triangle(white, down, 26px);
        bottom:-15px;
    }
    p {
        @include font-size(14px, 1.2);

        font-weight: 300;

        color: black;
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
            @include font-size(16px, 1.5);
        }
    }
}
.testimonial__heading {
    @include font-size(16px, 1.4);

    margin-bottom: 5px;
    margin-top: 35px;
    padding-left: 20px;

    text-transform: uppercase;

    color: black;
    text-align: left;
}
.testimonial__subheading {
    @include font-size(14px, 1);

    margin-bottom: 5px;

    color: lighten(black, 25%);

    @include media-breakpoint-up(md) {
        @include font-size(16px, 1);

        margin-bottom: 10px;
    }
}
.testimonial__extra {
    @include font-size(14px, 1);

    opacity: 0.5;
    color: black;

    @include media-breakpoint-up(md) {
        @include font-size(16px, 1);
    }
}
