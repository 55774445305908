.sidebar__testimonials {
    position: relative;

    margin-bottom: rem(20);
    padding: rem(5);
    padding-bottom: rem(50);

    text-align: center;

    border: 1px solid rgba($black, 0.2);
    background-color: $primary;

    @include media-breakpoint-up(lg) {
        padding: rem(15);
        padding-bottom: rem(50);
    }
    img {
        // max-width: 200px;
        width: 100%;;
        margin-bottom: rem(20);
    }
    h1 {
        font-size: rem(24);

        margin-bottom: 0;
    }
    // li,
    // p {
    //     color: $tertiary-lightest;
    // }
    .booking__details {
        padding-left: 0;

        list-style: none;
        i {
            color: $primary;
        }
        a {
            color: $secondary;
        }
    }
    .btn {
        position: absolute;
        bottom: 0;
        left: 0;
    }
}
