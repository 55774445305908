.properties {
    background-color: #F3F3F3;
}
.contentWrap {
    padding-top: 20px;

    @include media-breakpoint-up(md) {
        padding-top: 50px;;
    }
}

.sidebar {
    @include media-breakpoint-up(md) {
        margin-top: rem(15);
        margin-bottom: rem(15);
    }
    @include media-breakpoint-up(lg) {
        margin-top: rem(30);
        margin-bottom: rem(30);
    }
    @include media-breakpoint-up(xl) {
        margin-top: rem(50);
        margin-bottom: rem(50);
    }
}
.copy {
    max-width: 800px;
    margin: 0 auto;
    padding-top: 25px;
    padding-bottom: 25px;

    @include media-breakpoint-up(md) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
}
.commercial-image {
    margin-bottom: 50px;
    position: relative;
    &:after {
        position: absolute;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,0.2);
        top: 0;
        left: 0;
        z-index: 1;
    }
    img {
        z-index: 0;
    }
    .page-title {
        z-index: 2;
    }
}
.property {
    .carousel--nav {
        border-right: 1px solid rgba($black, 0.2);
        border-left: 1px solid rgba($black, 0.2);
    }
    .property__container {
        border-right: 1px solid rgba($black, 0.2);
        border-bottom: 1px solid rgba($black, 0.2);
        border-left: 1px solid rgba($black, 0.2);
    }
}

.commercial-sales {
    // padding: 0 0 100px;
    // background: #F3F3F3;
    .row {
        // justify-content: flex-start;
        justify-content: center;
    }
    //  > .container {
    //     max-width: 100%;
    //     padding: 0;
    // }
    .commercial-image {
        position: relative;

        overflow: hidden;

        @include media-breakpoint-down(sm) {
            height: 215px;
            img {
                position: absolute;
                top: 50%;
                right: -50%;
                left: -50%;

                max-width: none;
                height: 100%;
                margin: auto;

                transform: translateY(-50%);
            }
        }
        .page-title {
            position: absolute;
            top: 50%;
            right: 0;
            left: 0;

            transform: translateY(-50%);

            border: none;
            background: none;
        }
    }
    .page_intro {
        display: block;

        margin: 20px 0;

        text-align: center;
        h2 {
            font-size: 48px;
            font-weight: 300;

            @include media-breakpoint-down(sm) {
                font-size: 2rem;
            }
        }
        p {
            font-size: 16px;

            max-width: 800px;
            margin-right: auto;
            margin-bottom: 30px;
            margin-left: auto;

            text-align: center;
        }
    }
    .row {
        margin-right: -10px;
        margin-left: -10px;
        .col {
            padding-right: 10px;
            padding-left: 10px;
            @media (min-width: 1200px) and (max-width: 1599px) {
                flex: 0 0 33.33%;
                article {
                    max-width: 100%;
                }
            }
        }
    }
    article {
        width: 100%;
        min-width: 255px;
        max-width: 265px;
        margin-bottom: 20px;
        padding: 40px 20px;

        text-align: center;

        border: solid 1px #E4E5E5;
        border-radius: 3px;
        background: transparent;
        position: relative;
        overflow: hidden;
        min-height: 300px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        @include media-breakpoint-down(xl) {
            max-width: 100%;
        }
        svg {
            margin-bottom: 20px;
        }
        p {
            margin-bottom: 10px;
            color: white;
        }
        h3 {
            color: white;
        }
        .img_wrap {
            top: 50%;
            transform: translateY(-50%);
            left: -50%;
            right: -50%;
            margin: auto;
            min-height: 100%;
            min-width: 100%;
            width: auto;
            max-width: none;
            position: absolute;
            z-index: -1;
            img {
                width: 100%;
            }
        }
    }
    .commercial-wrap-container {
        width: 100%;
        margin-right: auto;
        margin-left: auto;
        padding-right: rem(15);
        padding-left: rem(15);

        @include media-breakpoint-up(sm) {
            max-width: 540px;
        }
        @include media-breakpoint-up(md) {
            max-width: 720px;
        }
        @include media-breakpoint-up(lg) {
            max-width: 960px;
        }
        @include media-breakpoint-up(xl) {
            max-width: 1240px;
            padding-right: rem(25);
            padding-left: rem(25);
        }
        @media all and (min-width: 1320px) {
            padding-right: rem(0);
            padding-left: rem(0);
        }
        @media all and (min-width: 1600px) {
            max-width: 1420px;
        }
    }
}
.contact_layout {
    padding-bottom: 50px;
    address {
        // margin-bottom: 20px;
        // padding-bottom: 20px;

        // border-bottom: solid 2px $tertiary;
        b {
            font-size: 120%;
        }
    }
}
.team_page_layout {
    padding: 20px 0;
    .profile_contact {
        b {
            display: block;

            width: 100%;
            padding-top: 10px;

            text-transform: uppercase;
        }
        a {
            display: block;

            width: 100%;
        }
    }
    .subtitle {
        font-size: 90%;
        font-weight: bold;
    }
}
blockquote {
    border-left: none;
}

.quote-badge {
    background-color: rgba(0, 0, 0, 0.2);
}

.quote-box {
    overflow: hidden;

    width: 325px;
    margin-top: -50px;
    margin-top: 25px;
    padding-top: -100px;

    color: white;
    border-radius: 17px;
    background-color: #4ADFCC;
    box-shadow: 2px 2px 2px 2px #E0E0E0;
}

.quotation-mark {
    font-family: "Times New Roman", Georgia, Serif;
    font-size: 100px;
    font-weight: bold;

    margin-top: -10px;

    color: white;
}

.quote-text {
    font-size: 19px;

    margin-top: -65px;
}



.container__left {
    @include media-breakpoint-up(lg) {
        padding-left: 0;
    }
}
.container__right {
    @include media-breakpoint-up(lg) {
        padding-left: 50px;
    }
}
div#mapText {
    float: none !important;

    margin-top: 30px!important;
    padding-left: 0!important ;
    form#drivingForm input.btn {
        width: auto!important  ;
        height: auto!important  ;

        background: $primary !important;
    }
}

#gdmap_canvas {
    width: 100% !important;
}

.properties-list {
    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-up(sm) {
        max-width: 540px;
        padding-right: rem(15);
        padding-left: rem(15);
    }
    @include media-breakpoint-up(md) {
        max-width: 720px;
    }
    @include media-breakpoint-up(lg) {
        max-width: 960px;
    }
    @include media-breakpoint-up(xl) {
        max-width: 1140px;
    }
    @media all and (min-width: 1600px) {
        max-width: 1140px;
    }
}
