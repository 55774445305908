.video-background {
  position: fixed;
  z-index: -99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  background: #000;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;
}
.video-background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  iframe {
    // display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
  }
}
#vidtop-content {
  top: 0;

  color: #fff;
}
.vid-info {
  position: absolute;
  top: 0;
  right: 0;

  width: 33%;
  padding: 1rem;

  color: #fff;
  background: rgba(0, 0, 0, 0.3);

  font-family: Avenir, Helvetica, sans-serif;
}
.vid-info h1 {
  margin-top: 0;

  font-size: 2rem;
  font-weight: 700;
  line-height: 1.2;
}
.vid-info a {
  display: block;

  margin: 1rem auto;

  transition: 0.6s background;
  text-align: center;
  text-decoration: none;

  color: #fff;
  border-bottom: none;
  background: rgba(0, 0, 0, 0.5);
}
.video-foreground {
    display: none;
    @include media-breakpoint-up(lg){
        display: block;  
    }
}
@media (min-aspect-ratio: 16/9) {
  .video-foreground {
    top: -100%;

    height: 300%;
  }
}
@media (max-aspect-ratio: 16/9) {
  .video-foreground {
    left: -100%;

    width: 300%;
  }
}
@media all and (max-width: 600px) {
  .vid-info {
    width: 50%;
    padding: 0.5rem;
  }
  .vid-info h1 {
    margin-bottom: 0.2rem;
  }
}
@media all and (max-width: 500px) {
  .vid-info .acronym {
    display: none;
  }
}
