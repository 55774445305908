.dropdown {
  &.show {
    @include media-breakpoint-up(lg) {
      background-color: $primary;
    }
  }
}
.dropdown-menu {
  border: 0;
  border-radius: 0;
  background-color: $primary;
  .dropdown-item {
    color: $white;

    font-weight: 100;
    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}
