.sidebar__details {
  h1 {
    margin-bottom: 0;

    color: $tertiary-lightest;

    font-size: rem(32);
    font-weight: bold;

    @include media-breakpoint-up(lg) {
      font-size: rem(42);
    }
  }
  h2 {
    font-size: rem(20);
    line-height: 1.2em;

    @include media-breakpoint-up(lg) {
      font-size: rem(28);
    }
  }
  .property-details {
    ul {
      @include media-breakpoint-up(lg) {
        flex-wrap: wrap;
        justify-content: flex-start;
      }
    }
    li {
      @include media-breakpoint-up(lg) {
        flex: 0 0 auto;
      }
      @include media-breakpoint-up(xl) {
        font-size: rem(16);
      }
      &:not(:last-child) {
        @include media-breakpoint-up(lg) {
          margin-right: rem(10);
          padding-right: rem(10);
        }
      }
    }
  }
}
