.property-details {
  ul {
    display: flex;

    margin-bottom: 0;
    padding: rem(10) rem(5);

    list-style: none;

    color: $secondary;
    border-top: 1px solid rgba($black, 0.1);
    // box-shadow: 0 0 20px 0 rgba($black, 0.1);
    border-bottom: 1px solid rgba($black, 0.1);
    // @include border-radius(rem(5));

    @include media-breakpoint-up(md) {
      margin-top: rem(20);
      margin-bottom: rem(20);
    }
    @include media-breakpoint-up(lg) {
      border: 1px solid rgba($black, 0.1);
    }

    li {
      position: relative;

      display: flex;
      align-items: center;
      flex: 1 0 auto;
      flex-direction: column;
      justify-content: center;

      min-width: rem(25);

      font-size: rem(14);

      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
      &:not(:last-child) {
        &::before {
          position: absolute;
          top: 0;
          right: 0;

          width: 1px;
          height: 100%;

          content: "";

          @include media-breakpoint-up(lg) {
            background-color: rgba($black, 0.1);
          }
        }
      }
      i {
        @include media-breakpoint-up(sm) {
          padding-right: rem(5);
        }
      }
    }
    span {
      display: none;

      @include media-breakpoint-up(sm) {
        display: inline-block;

        padding-right: rem(5);
        padding-left: rem(5);
      }
      @include media-breakpoint-only(md) {
        display: none;
      }
      em {
        position: absolute;

        font-size: rem(9);
      }
    }
  }
}
