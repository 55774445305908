@font-face {
  font-family: 'fontello';
  src: url('../fonts/fontello//fontello.eot?95066931');
  src: url('../fonts/fontello//fontello.eot?95066931#iefix') format('embedded-opentype'),
       url('../fonts/fontello//fontello.woff2?95066931') format('woff2'),
       url('../fonts/fontello//fontello.woff?95066931') format('woff'),
       url('../fonts/fontello//fontello.ttf?95066931') format('truetype'),
       url('../fonts/fontello//fontello.svg?95066931#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../fonts/fontello//fontello.svg?95066931#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: 'fontello';
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-phone:before { content: '\e800'; } /* '' */
.icon-search:before { content: '\e801'; } /* '' */
.icon-twitter:before { content: '\f099'; } /* '' */
.icon-facebook:before { content: '\f09a'; } /* '' */
.icon-linkedin:before { content: '\f0e1'; } /* '' */
.icon-direction:before { content: '\f124'; } /* '' */

.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-angle-left:before { content: '\f104'; } /* '' */
.icon-angle-right:before { content: '\f105'; } /* '' */
.icon-plus:before { content: '\e802'; } /* '' */
