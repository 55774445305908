// Font-face
@font-face {
    font-family: 'picto-foundry-household';
    src: local('picto-foundry-household'),
        url('../fonts/picto-foundry-household.woff2') format('woff2'),
        url('../fonts/picto-foundry-household.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    font-display: auto;
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
        url('../fonts/OpenSans-Regular.woff2') format('woff2'),
        url('../fonts/OpenSans-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
        url('../fonts/OpenSans-Bold.woff2') format('woff2'),
        url('../fonts/OpenSans-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'Open Sans';
    src: local('Open Sans Light'), local('OpenSans-Light'),
        url('../fonts/OpenSans-Light.woff2') format('woff2'),
        url('../fonts/OpenSans-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

// Font families
$font-main: "Open Sans", sans-serif;
$font-icon: "picto-foundry-household", sans-serif;
$font-fontello: "fontello";


$font-size-base: 16px;
