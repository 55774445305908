$navbar-height: 76px;
$navbar-height-lg: 104px;
$navbar-height-xl: 112px;

body {
  padding-top: $navbar-height;

  @include media-breakpoint-up(lg) {
    padding-top: $navbar-height-lg;
  }
  @include media-breakpoint-up(xl) {
    padding-top: $navbar-height-xl;
  }
}

@include media-breakpoint-down(md) {
  .offcanvas-collapse {
    position: fixed;
    top: $navbar-height;
    bottom: 0;
    left: 100%;

    visibility: hidden;
    overflow-y: auto;

    width: 100%;
    padding-right: 1rem;
    padding-left: 1rem;

    transition-timing-function: ease-in-out;
    transition-duration: 0.3s;
    transition-property: left, visibility;

    background-color: $white;
    box-shadow: 0 10px 20px 0 rgba(black, 0.2);
  }
  .offcanvas-collapse.open {
    left: 0;

    visibility: visible;
  }
}
.filters--offcanvas-collapse {
  position: fixed;
  z-index: 10;
  top: $navbar-height;
  bottom: 0;
  left: 100%;

  visibility: hidden;
  overflow-x: hidden;
  overflow-y: auto;

  width: 300px;
  padding-right: rem(10);
  padding-left: rem(10);

  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  transition-property: left, visibility;

  background-color: transparent;

  @include media-breakpoint-up(md) {
    width: 400px;
  }
  @include media-breakpoint-up(lg) {
    top: $navbar-height-lg;

    width: 500px;
  }
  @include media-breakpoint-up(xl) {
    top: $navbar-height-xl;
  }
}
.filters--offcanvas-collapse.open {
  left: calc(100% - 300px);

  visibility: visible;

  @include media-breakpoint-up(md) {
    left: calc(100% - 400px);
  }
  @include media-breakpoint-up(lg) {
    left: calc(100% - 500px);
  }
}

.nav-scroller {
  position: relative;
  z-index: 2;

  overflow-y: hidden;

  height: 2.75rem;
  .nav {
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    flex-wrap: nowrap;

    margin-top: -1px;
    padding-bottom: 1rem;

    text-align: center;
    white-space: nowrap;

    color: rgba(255, 255, 255, 0.75);
  }
}
