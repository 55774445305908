.homepage {
  .footer {
    background-color: transparent;
  }
}
.footer {
  overflow-x: hidden;

  padding-top: rem(10);
  padding-bottom: rem(10);

  border-top: 2px solid $primary;
  background-color: $secondary;
  .footer__container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    padding-right: rem(15);
    padding-left: rem(15);

    text-align: center;

    @include media-breakpoint-up(sm) {
      max-width: 540px;
    }
    @include media-breakpoint-up(md) {
      width: 100%;
      max-width: none;
      padding-right: rem(40);
      padding-left: rem(40);

      text-align: inherit;
    }
    .row {
      @include media-breakpoint-up(md) {
        align-items: center;
        justify-content: center;
      }
    }
  }
  ul {
    padding-left: 0;

    list-style: none;
    li {
      display: inline-block;
      a {
        color: $white;
      }
    }
    &.footer__list {
      margin-top: rem(15);
      margin-bottom: rem(15);

      color: $white;

      i {
        color: $primary;
      }
      &.footer__list--social {
        i {
          color: $white;
        }
      }
    }
  }
  .footer__images {
    display: flex;
    overflow-x: scroll;

    @include media-breakpoint-up(lg) {
      overflow: hidden;
      flex-wrap: wrap;
    }
    img {
      // margin-right: rem(10);

      @include media-breakpoint-up(lg) {
        width: auto;
      }
    }
  }
}
