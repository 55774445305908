// Main colours
$primary:   #d50048;
$secondary: #121f27;
$tertiary:  #1b2f3b;

$primary-darker:   darken($primary, 5%);
$secondary-darker: darken($secondary, 5%);
$tertiary-darker:  darken($tertiary, 5%);

$primary-lighter:   lighten($primary, 5%);
$secondary-lighter: lighten($secondary, 5%);
$tertiary-lighter:  lighten($tertiary, 5%);

$tertiary-lightest:  lighten($tertiary, 30%);

// White/Black
$white:     #ffffff;
$black:     #000000;
$off-white: #f2f2f2;
$off-black: #1a1a1a;

// Social Colours

$twitter:   #00aced;
$facebook:  #3b5998;
$linkedin:  #007bb6;
$youtube:   #bb0000;
$tumblr:    #32506d;
$instagram: #517fa4;
$vimeo:     #aad450;
$lastfm:    #D51007;
