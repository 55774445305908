.filters {
  background-color: $white;
  box-shadow: 0 0 20px 0 rgba($black, 0.2);
}
.filters__container {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  margin-bottom: rem(15);
  padding: rem(15) rem(20);

  background: $white;

  @include media-breakpoint-up(md) {
    padding: rem(30);
  }
  h3 {
    margin-top: rem(20);
    margin-bottom: rem(30);

    font-size: rem(16);
  }

  .selector {
    width: 100%;
    margin-top: rem(10);
    margin-bottom: rem(10);
    padding: rem(10);

    border: 1px solid rgba($secondary, 0.2);
    border-radius: 0;
    background: white;
    box-shadow: none;

    appearance: none;
  }
  .filters__range {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: rem(20);
    input {
      width: 100%;
      padding: rem(10);

      border: 1px solid rgba($secondary, 0.2);
      &::before {
        content: '€';
      }
    }
    .filters__currency {
      position: relative;
      width: calc(50% - 30px);
      input {
        padding-left: rem(25);
      }
      .filters__float {
        position: absolute;
        opacity: 0.5;
        top: 50%;
        left: rem(10);
        transform: translateY(-50%);

      }
    }
  }
  .filters__list {
    margin-bottom: rem(20);
    padding-left: 0;

    list-style: none;
    a {
      position: relative;

      display: flex;

      width: 100%;
      margin-top: rem(10);
      margin-bottom: rem(10);

      cursor: pointer;
      text-decoration: none;

      color: $secondary;

      &::before {
        position: absolute;
        top: rem(3);
        right: 0;

        display: inline-block;

        width: 16px;
        height: 16px;
        margin-right: 6px;

        content: "";

        border: 1px solid rgba($black, 0.2);

        @include border-radius(100%);
      }
      img {
        width: 25px;
        height: 100%;
        margin-right: rem(25);
      }

      &.is-ticked {
        // &::before {
        //   background: $primary;
        // }
        &::after {
          position: absolute;
          top: rem(6);
          right: rem(3);

          display: inline-block;

          width: 10px;
          height: 10px;
          margin-right: 6px;

          content: "";

          background: $primary;

          @include border-radius(100%);
        }
      }
    }
  }
  .btn--red {
    width: 100%;
  }
}
