.homepage {
  .navbar {
    background-color: transparent;

    @include media-breakpoint-up(lg) {
      padding-top: rem(50);
    }
  }
  &.has-scrolled {
    .navbar {
      background-color: $secondary;

      @include media-breakpoint-up(lg) {
        padding-top: rem(15);
      }
    }
  }
}
header {
  width: 100%;
  float: left;
  display: block;
  overflow: hidden;
}
.navbar {
  background-color: $secondary;

  @include media-breakpoint-up(lg) {
    padding: rem(15);
  }
  .navbar__container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    width: 100%;
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-up(sm) {
      max-width: 540px;
    }
    @include media-breakpoint-up(md) {
      max-width: 720px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 960px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 1240px;
      padding-right: rem(25);
      padding-left: rem(25);
    }
    @media all and (min-width: 1320px) {
      padding-right: rem(0);
      padding-left: rem(0);
    }
    @media all and (min-width: 1600px) {
      max-width: 1420px;
    }
  }
  .hamburger {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
  .navbar-brand {
    h1 {
      position: absolute;

      text-indent: -5000px;
    }
    .navbar__images {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;

      width: 100%;
      img {
        width: 100%;
        max-width: 125px;

        @include media-breakpoint-up(lg) {
          max-width: 250px;
        }
        &:last-child {
          max-width: 95px;
          height: auto;
          padding-left: rem(20);

          @include media-breakpoint-up(lg) {
            max-width: 100px;
            padding-left: rem(30);
          }
          @include media-breakpoint-up(xl) {
            max-width: 140px;
          }
        }
      }
    }
  }
  .navbar-nav {
    @include media-breakpoint-up(lg) {
      align-items: center;
    }
  }
  ul {
    padding: rem(20);

    @include media-breakpoint-up(lg) {
      padding: 0;
    }
  }
  .nav-item {
    @include media-breakpoint-down(md) {
      margin-bottom: 5px;
    }
    &:first-child {
      @include media-breakpoint-down(md) {
        margin-top: rem(10);
      }
    }
    &.nav-item--button {
      .nav-link {
        margin-top: 0;
        padding: rem(10) rem(15);
        color: $white;
        background-color: $primary;

        @include border-radius(rem(4));
        @include media-breakpoint-up(lg) {
          margin-left: rem(15);
        }
        @include media-breakpoint-up(xl) {
          margin-left: rem(25);
          padding: rem(10) rem(25);
        }
        &.btn {
          border-radius: 0px;
        }
      }
    }
    .nav-link {
      display: inline-block;

      @include media-breakpoint-down(md) {
        margin-bottom: rem(10);

        color: $primary;

        font-size: rem(20);
      }
      @include media-breakpoint-up(lg) {
        text-transform: uppercase;

        color: $white;

        font-size: rem(12);
      }
      @include media-breakpoint-up(xl) {
        font-size: rem(14);
      }
      @media all and (min-width: 1440px) {
        margin-right: rem(10);

        font-size: rem(16);
      }
    }
  }
}
