// Component : Modal

.modal {
  .form-group {
    margin-bottom: rem(10);

    text-align: left;
    label {
      margin-bottom: 0;

      text-align: left;
      text-transform: uppercase;

      font-size: rem(12);
    }
  }
  .btn {
    position: relative;
  }
  .close {
    padding: rem(15);

    border: 0;
    background-color: transparent;
  }
}
